footer{
    &#main-footer{
        color: $brand-secondary;
        text-transform: uppercase;
        font-size: $f-s-btn;
        letter-spacing: $f-ls-small;
        padding: $space-big 0;
        @include media-breakpoint-down(xs) {
            text-align: center;
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            @include media-breakpoint-down(xs) {
                text-align: center;
                margin: 0 auto;
            }
            &.footer-social{
                text-align: right;
                @include media-breakpoint-down(xs) {
                    text-align: center;
                }
                li{
                    display: inline-block;
                    a{
                        display: block;
                        font-size: $f-s-social;
                        color: $brand-secondary;
                        padding: $space-small $space-med;
                        &:hover, &:active, &:focus{
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
}