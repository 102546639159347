.img-link{
    border-radius: $b-radius-none;
    position: relative;
    transition: $transition-xs;
    border: none;
    &:before{
        content:"";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: $brand-alternative;
        opacity: $op-none;
        transition: $transition-xs;
    }
    .card-img-overlay{
        top: inherit;
        z-index: 3;
        bottom: $space-normal-minus;
        opacity: $op-none;
        transition: $transition-xs;
    }
    img, .card-img{
        border-radius: $b-radius-none;     
        position: relative;
        z-index: 1;
    }
    h5, .card-title{
        color: $color-white;
        font-weight: $f-light;
        vertical-align: text-bottom;
    }
    p, .card-text{
        font-size: $f-s-btn;
        text-transform: uppercase;
        color: $color-white;
        letter-spacing:  $f-ls-small;
        vertical-align: text-bottom;
    }
    &:hover{
        .card-img-overlay{
            opacity: $op-total;
            bottom: 0;
        }
        &:before{
            opacity: $op-med;
        }
    }
}

.work-list{
    padding-top: $space-big;
}