.owl-carousel{
    position: relative;
    .owl-dots{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $space-normal;
    }
    .owl-nav{
        position: absolute;
        left: 0;
        right: 0;
        font-size: $f-s-nav-slide;
        .owl-prev, .owl-next{
            display: block;
            position: absolute;
            width: $space-max;
            height: $space-max;
            cursor: pointer;
            border-radius: $b-radius-total;
            padding: 0;
            transition: $transition-xs;
            line-height: $space-max;
            text-align: center;
            background: none;
            border: $brand-secondary solid 1px;
            color: $brand-secondary;
            &:hover, &:active, &:focus{
                background: $color-black-1;
                border: $color-black-1 solid 1px;
                color: $color-white;
            }
        }
    }
    &.owl-home-items-carousel{
        min-height: 100%;
        height: 100vh;    
        vertical-align: middle;
        text-align: center;
        .owl-nav{
            bottom: $space-big;
            text-align: left;
            @extend .container;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            .owl-prev, .owl-next{
                position: relative;
                left: inherit;
                right: inherit;
                display: inline-block;
                margin: 0;
            }
        }
        .owl-stage-outer{
            width: 100%;   
            min-height: 100%;
            height: 100vh;
            .owl-item{
                width: 100%;
                min-height: 100%;
                height: 100vh;
                text-align: left;
                .item{
                    width: 100%;
                    position: relative;
                    min-height: 100%;
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center; 
                    background-repeat: no-repeat !important;
                    background-size: cover !important;
                    background-position: center bottom !important;
                    @include media-breakpoint-down(md) {
                        background-size: 100% !important;    
                    }
                    .row{
                        .col-text {
                            position: inherit;  
                            h6{
                                position: absolute;
                                bottom: $space-big;
                                margin-left: 145px;
                                @include media-breakpoint-down(lg) {
                                    position: relative;
                                    margin-left: 0;
                                    bottom: inherit;
                                }
                            } 
                        }
                    }
                }
            }    
        }
    }
    &.owl-gallery-items-carousel{
        margin-bottom: $space-big;
        .owl-stage-outer{
            .owl-stage{
                .owl-item{
                    .item{
                        a{
                            img{
                                transition: $transition-xs;
                            }
                            &:hover, &:active, &:focus{
                                img{
                                    opacity: $op-med;
                                }
                            }
                        }
                    }
                }
            }
        }
        .owl-nav{
            bottom: 90px;
            text-align: left;
            z-index: 10;
            .owl-prev{
                left: $space-normal;    
            }
            .owl-next{
                right: $space-normal;    
            }
            .owl-prev, .owl-next{
                color: $color-white;
                background: $color-black-2;
                &:hover, &:active, &:focus{
                    background: $color-black-5;
                }
            }
        }
        .owl-dots{
            bottom: $space-normal;
            @include media-breakpoint-down(sm){
                display: none;
            }
            .owl-dot{
                span{
                    background: $color-white-5;
                }
                &:hover, &:active, &:focus{
                    span{
                        background: $color-white;    
                    }
                }
                &.active{
                    span{
                        background: $color-black;
                    }
                }
            }
        }
    }
}