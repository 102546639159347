// general form
.general-form{
  .form-group, .form-check{
    margin-bottom: 1rem;
    label{
      text-transform: uppercase;
      font-size: $f-s-btn;
      letter-spacing: $f-ls-small;
      color: $brand-secondary;
      padding: 0 $space-med;
      margin: 0;
    }
    .form-control{
      background: $color-black-s;
      border: $color-black-0 solid 1px;
      padding: $space-med !important;
      border-radius: $b-radius-small;
      font-weight: $f-regular !important;
      font-family: $font-general;
      font-size: $base-font-size;
      height: auto;
    }
  }
}

.gform_body{
  @extend .general-form;
  ul{
    li{
      &.gfield{
        @extend .form-group;  
        input, textarea{
          @extend .form-control;  
        }
      }
      &.gchoice_1_6_1{
        @extend .form-check;
        br{
          display: none;
        } 
      }
    }
  }
}

.gform_footer{
  text-align: right;
  .gform_button{
    @extend .btn;
    @extend .btn-primary;
  }
}

.gform_wrapper{
  li{
    &.gfield{
      &.gfield_error{
        background: none !important;
        border: none !important;
      }
    }
  }
  .field_description_below {
    .gfield_description{
      padding-top: 0 !important;
    }
  }
} 