
.work-list{
    @extend .row;
    .masonry-item{
        @extend .col-md-4;
        @extend .col-sm-6;
        @extend .col;
        padding-bottom: 15px;
    }
    
}