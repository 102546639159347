section{
    &.section-box{
        padding: $space-big 0;
        header{
            &.section-header{
                padding-top: $space-top;
                padding-bottom: $space-big;
                h6{
                    margin-bottom: $space-med-minus;
                }
            }
        }
        footer{
            &.section-footer{
                border-top: $color-black-s solid 1px;
                padding-top: $space-normal;
                ul{
                    list-style: none;
                    padding: 0;
                    li{
                        text-align: center;
                        a{
                            width: $space-max;
                            height: $space-max;
                            text-align: center;
                            display: inline-block;
                            border-radius: $b-radius-total;
                            color: $brand-secondary;
                            border: $color-black-1 solid 1px;
                            line-height: $space-max;
                            margin: 0 auto;
                            &:hover, &:focus, &:active{
                                background: $color-black-5;
                                border: $color-black-1 solid 1px;
                                color: $color-white;
                            }
                        }
                        &:nth-child(1){
                            text-align: left;
                            a{
                                margin: 0;
                            }
                        }
                        &:nth-child(2){
                            a{
                               border: $color-white-0 solid 1px;
                            }
                        }
                        &:nth-child(3){
                            text-align: right;
                            a{
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        article{
            margin-bottom: $space-normal;
            h1{
                font-size: $f-s-h2;
            }
            blockquote{
                color: $brand-primary;
                margin-left: -8.3333333333%;
                font-style: italic;
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }
            a{
                text-decoration: underline;
                font-style: italic;
            }
        }
        &.section-home-slide{
            background: $brand-bg;
            padding: 0;
        }
        &.section-page{
            padding-top: 0;
        }
    }    
}