header{
    &#main-header{
        nav{
            padding-top: $space-big;
            padding-bottom: $space-normal;
            @include media-breakpoint-down(md) {
                padding-top: $space-normal;
            }
            .navbar-brand{
                width: $z-logo;
                height: auto;
                position: relative;
                z-index: 600;
                img{
                    display: block;
                    line-height: 0;
                    width: 100%;
                    height: auto;
                }
            }
            .navbar-toggler {
                border: none;
                color: $brand-primary;
                background: $color-white;
                border-radius: $b-radius-total;
                width: $space-max;
                height: $space-max;
                padding: 0;
                text-align: center;
                z-index: 600;
            }
            .nav-primary{
                @include media-breakpoint-down(md) {
                    background: $color-white-8;
                    min-height: 100%;
                    height: 100vh;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    z-index: 500;
                }
                .nav{
                    @include media-breakpoint-down(md) {
                        padding-top: 20%;
                    }
                    li{
                        a{
                            display: block;
                            @extend .link;
                            font-size: $base-font-size;
                            padding: $space-med;
                            color: $brand-secondary;
                            &:hover, &:active, &:focus{
                                color: $brand-primary;
                            }
                            @include media-breakpoint-down(md) {
                                text-align: center;
                                font-size: $f-s-h5;
                            }
                        }
                        @include media-breakpoint-down(md) {
                            display: block;
                            width: 100%;
                        }
                        &.active{
                            a{
                                color: $brand-primary;
                            }
                        }
                    }
                }
            }
        }
    }
}