.link{
    display: inline-block;
    font-size: $f-s-btn;
    text-transform: uppercase;
    letter-spacing: $f-ls-small;
    transition: $transition-xs;
    span{
        display: inline-block;
    }
    img{
        display: inline-block;
    }
    i, svg{
        display: inline-block;
        width: $space-normal !important;
    }
}

.btn{
    font-size: $f-s-btn;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: $f-ls-small;
    padding: $space-med $space-normal;
    color: $color-black;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 4px solid $color-black;
    transition: $transition-xs;
    border-radius: $b-radius-none;
    i, svg{
        display: inline-block;
        width: $space-normal !important;
    }
    &:hover, &:active, &:focus{
        background: none;  
        border-color: $color-black-9;
        color: $color-white;  
    }
    &.btn-primary{
        border-color: $brand-primary;
        color: $brand-primary;
        &:hover, &:active, &:focus{   
            border-color: $brand-alternative; 
            color: $brand-alternative;
        }
    }
    &.btn-secondary{
        border-color: $brand-secondary;
        color: $brand-secondary;
        &:hover, &:active, &:focus{   
            border-color: $brand-primary; 
            color: $brand-primary;
        }
    }
}