body{
    font-size: $base-font-size;
    color: $brand-text;
    line-height: $base-line-height;
    font-weight: $f-regular;
    a{
        color: $brand-primary;
        transition: $transition-xs;
        &:hover, &:focus, &:active{
            color: $color-black;
            text-decoration: none;
        }
    }
    img{
        max-width: 100%;
        height: auto;
        line-height: 0;
    }
    figure{
        figcaption{
            padding: $space-small 0;
            font-size: $f-s-btn;
            color: $brand-secondary;
            text-align: right;
            display: block;
        }
    }    
    h1, h2, h3, h4{
        text-transform: uppercase;
        font-weight: $f-regular;
        letter-spacing: $f-ls-small;
        font-weight: $f-light;
        line-height: $base-line-height;
        line-height: $cap-height;
    }
    h1{
        font-size: $f-s-h1;
    }
    h2{
        font-size: $f-s-h2;
    }
    h3{
        font-size: $f-s-h3;
    }
    h4{
        font-size: $f-s-h4;
    }
    h5{
        font-size: $f-s-h5;
    }
    h6{
        font-size: $base-font-size;
        text-transform: uppercase;
        letter-spacing: $f-ls-small;
        color: $brand-primary;
        line-height: $base-line-height;
        small{
            display: block;
            color: $brand-secondary;
            font-size: $brand-secondary;
        }
    }
    .date{
        small{
            font-size: $f-s-btn;
            display: block;
            letter-spacing: $f-ls-small;
        }
    }
}


