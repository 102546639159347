// font path
$font-path:             "../fonts/";

// images path
$image-path:            "../images/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:          rgba(98, 48, 73, 1);
$brand-secondary:        rgba(164, 150, 157, 1);
$brand-alternative:      rgba(78, 65, 72, 1);
$brand-text:             rgba(82, 76, 79, 1);
$brand-bg:               rgba(246, 244, 245, 1);

$color-black:            rgba(0, 0, 0, 1);
$color-black-9:          rgba(0, 0, 0, .95);
$color-black-8:          rgba(0, 0, 0, .8);
$color-black-5:          rgba(0, 0, 0, .5);
$color-black-2:          rgba(0, 0, 0, .2);
$color-black-1:          rgba(0, 0, 0, .1);
$color-black-s:          rgba(0, 0, 0, .06);
$color-black-0:          rgba(0, 0, 0, 0);

$color-white:            rgba(255, 255, 255, 1);
$color-white-8:          rgba(255, 255, 255, .8);
$color-white-5:          rgba(255, 255, 255, .5);
$color-white-2:          rgba(255, 255, 255, .2);
$color-white-1:          rgba(51, 50, 50, 0.1);
$color-white-0:          rgba(255, 255, 255, 0);

$color-red:              #f21458;
$color-green:            #91e842;

// Font
$font-general:          'Lato', sans-serif;
$f-light:               300;
$f-regular:             400;
$f-bold:                900;


$f-s-btn:               11px;
$f-s-nav-slide:         18px;
$f-s-social:            20px;

$f-s-h1:                48px;
$f-s-h2:                32px;
$f-s-h3:                26px;
$f-s-h4:                22px;
$f-s-h5:                18px;

$f-ls-small:            0.09em;

// spaces
$space-small:           3.15px;
$space-med:             7.5px;
$space-normal:          15px;
$space-big:             30px;
$space-max:             60px;
$space-top:            110px;
$space-top-mobile:     50px;
$space-small-minus:           -3.15px;
$space-med-minus:             -7.5px;
$space-normal-minus:          -15px;
$space-big-minus:             -30px;
$space-max-minus:             -60px;



// size
$z-logo:                220px;

// opacity
$op-total:               1;
$op-med:                .5;
$op-none:                0;

// b-radius
$b-radius-none:          0px;
$b-radius-small:         2px;
$b-radius-total:         300px;

// b-shadow
$b-shadow-small:         0px 8px 21px -2px rgba(0,0,0,0.22);
$b-shadow-big:           0px 8px 34px -2px rgba(0,0,0,0.32);

// TRANSITIONS
$transition-xs:   all .2s ease-out;